import React, { useState, useEffect } from 'react';
import axios from "axios";
import './App.css';
import euroeIcon from './euroe.svg';
import glasses from './cool.png';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import TweetButton from './tweetButton';

// Register the chart elements
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const blockchainNames = {
  ETH: 'Ethereum',
  POLY: 'Polygon',
  ARB: 'Arbitrum One',
  AVAX: 'Avalanche',
  SOL: 'Solana',
  OP: 'Optimism',
  CCD: 'Concordium',
  BASE: 'Base',
  GNO: 'Gnosis'
};

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadTime, setLoadTime] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);

  // Use the serverless proxy URL here
  const proxyUrl = "https://euroe.cool/api/proxy";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Update the request URL to use the proxy
        const response = await axios.get(proxyUrl);
        const fetchedData = response.data;
        setData(fetchedData);
        const total = fetchedData.reduce((acc, token) => acc + parseFloat(token.supply), 0);
        setTotalSupply(total);
      } catch (error) {
        setError(error.toString());
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    const time = new Date();
    setLoadTime(time);
  }, []);

  const formattedData = data ? data.map(token => ({
    ...token,
    name: blockchainNames[token.name] || token.name,
    supply: parseFloat(token.supply) // Ensure the supply is a number for correct sorting
  })).sort((a, b) => b.supply - a.supply) : []; // Sort in descending order
  


  const formattedTotalSupply = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'EUR',
    notation: 'compact', // This will format numbers using compact notation
    maximumFractionDigits: 2 // Adjust number of decimal places as needed
  }).format(totalSupply);

  function formatCurrency(value) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2, // You can adjust the number of decimal places here
    }).format(value);
  }  

  const getIconPath = (name) => {
    // Assuming the icon's name is the same as the blockchain's name but in lowercase
    // and that they are stored in the public folder as SVGs
    return `/icons/${name.toLowerCase()}.svg`;
  };

  const pieData = formattedData ? {
    labels: formattedData.map(token => token.name),
    datasets: [{
      label: 'Supply Distribution',
      data: formattedData.map(token => parseFloat(token.supply)),
      backgroundColor: [
        '#CDFADB',
        '#F6FDC3',
        '#FFCF96',
        '#FF8080',
        '#527853',
        '#F9E8D9',
        '#F7B787',
      ],
      borderColor: '#243c49',
      borderWidth: 8,
      hoverBorderColor: '#243c49', 
    }],
  } : {};

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true, // Use point style for the legend items (circles by default)
          boxWidth: 40, // Width of the legend color box, adjust as needed
          padding: 20, // Space between legend items
          color: 'white',
        }
      },
      datalabels: {
        display: true,
        color: "white",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function(context) {
            // Assuming 'context.raw' is the raw value for the tooltip
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            // Format the number as a currency
            let amount = context.raw;
            let percentage = (amount / totalSupply * 100).toFixed(2) + '%';
            let formattedAmount = new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(amount);
            let labelString = [formattedAmount,percentage + " of total"];
            return labelString;
          }
        },  
        backgroundColor: '#245549',
        borderColor: 'rgba(36, 60, 73, 0.2)',
        borderWidth: 2,
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 14,
        },
        padding: 10,
        displayColors: false,
        cornerRadius: 3,
        position: 'nearest',
      },
      datalabels: {
        color: 'black',
        formatter: (value, context) => {
          let percentage = (value / totalSupply * 100).toFixed(1) + '%';
          return percentage;
        },  
        display: 'auto',  
        clamp: true,
        align: 'end',
        clip: true
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  };
  

  if (loading) return <div id="main"><div className="main-title"><img src={glasses} className="loadingCool" alt="cool" /> euroe.cool</div><div className='loading-text'>LOADING...</div></div>;
  if (error) return <div id="error"><p>Error: {error}</p></div>;

  return (
    <div id="main">
      <div className="main-title"><img src={glasses} alt="cool" /> euroe.cool</div>
      <div className="total-supply">{formattedTotalSupply}</div>
      <div className="issued-text">ISSUED</div>
      <div className="timestamp">as of {loadTime && loadTime.toLocaleString()}</div>
      <div id="supplyList">
        <div className="blockchain-list">
          {formattedData.map((token, index) => (
            <div key={index} className="blockchain-item">
              <img src={getIconPath(token.name)} alt={`${token.name} icon`} className="blockchain-icon" />
              <span className="blockchain-name">{token.name}</span>
              <span className="blockchain-supply">{formatCurrency(parseFloat(token.supply))}</span>
            </div>
          ))}
          <div className="blockchain-item totals-row">
            <img src={euroeIcon} alt="EUROe icon" className="blockchain-icon" />
            <span className="blockchain-name">Total</span>
            <span className="blockchain-supply">{formatCurrency(parseFloat(totalSupply))}</span> {/* Render the total supply */}
          </div>
        </div>
        <div id="supplyChart">
          {pieData && <Pie data={pieData} options={options} />}
          <p>You can click a blockchain to filter the data</p>
        </div>
        <div id="tweet">
          <TweetButton data={formattedData} />
        </div>
        <div id="broughtBy">
          <p>Brought to you by</p>
          <a class="ape" href="https://ape.llc" target="_blank" rel="noreferrer">ape.llc</a>
        </div>
        <div id="footer">
          <p>
            This is an unaudited and third-party snapshot of the current EUROe supply. Data from Membrane Finance and EUROe attestations can be found <a href="https://www.euroe.com/transparency-and-regulation" target="_blank" rel="noreferrer">here</a>. 
            <br/><br/>
            <a href="https://ape.llc" class="apeLink" target="_blank" rel="noreferrer">ape.llc</a> is not affiliated with Membrane Finance or EUROe. 
            <br/><br/>
            &copy; <a href="https://ape.llc" class="apeLink" target="_blank" rel="noreferrer">ape.llc</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;