import React from 'react';
import { FaTwitter } from 'react-icons/fa';

// Helper function to format the numbers with K, M, or B
function formatNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + 'B';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + 'M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + 'K';
  } else {
    return num.toString();
  }
}

const TweetButton = ({ data }) => {
  // Calculate the total supply and format it
  const totalSupply = formatNumber(data.reduce((acc, blockchain) => acc + parseFloat(blockchain.supply), 0));

  // Sort the data and pick the top 3
  const topThree = [...data] // Copy the data array before sorting
    .sort((a, b) => parseFloat(b.supply) - parseFloat(a.supply))
    .slice(0, 3);

  // Calculate the total for percentage calculations
  const total = data.reduce((acc, blockchain) => acc + parseFloat(blockchain.supply), 0);

  // Format the tweet message
  const tweetMessage = `Total $EUROe: €${totalSupply}\n\n` +
    topThree.map((blockchain, i) => {
      const emoji = ['🥇', '🥈', '🥉'][i];
      const percentage = ((parseFloat(blockchain.supply) / total) * 100).toFixed(2);
      return `${emoji} #${blockchain.name.toLowerCase()} €${formatNumber(parseFloat(blockchain.supply))} (${percentage}%)`;
    }).join('\n') +
    `\n\nhttps://euroe.cool`;

  // Encode the tweet message for use in the URL
  const encodedTweet = encodeURIComponent(tweetMessage);

  // Construct the twitter share URL
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodedTweet}`;

  return (
    <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className="twitter-share-button">
      <FaTwitter /> Tweet Supply
    </a>
  );
};

export default TweetButton;
